<template>
  <div class="w-full bg-night-blue flex flex-inline text-nav text-white justify-around items-center pt-3 pb-7">
    <router-link :to="{name: 'events'}" class="flex flex-col items-center">
      <CalendarIcon style="height: 24px"></CalendarIcon>
      Evénements
    </router-link>
    <router-link :to="{name: 'home'}" class="flex flex-col items-center">
      <HomeIcon style="height: 24px"></HomeIcon>
      Home
    </router-link>
    <router-link :to="{name: 'settings'}" class="flex flex-col items-center">
      <Cog6ToothIcon style="height: 24px"></Cog6ToothIcon>
      Réglages
    </router-link>
  </div>
</template>

<script>

import {HomeIcon, CalendarIcon, Cog6ToothIcon} from '@heroicons/vue/24/outline';

export default {
  name: "MobileMenu",
  components: {
    HomeIcon,
    CalendarIcon,
    Cog6ToothIcon
  }
}
</script>

<style scoped>

</style>
