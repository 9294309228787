<template>
  <div v-if="flight.id" class="bg-white rounded-lg custom-shadow overflow-hidden">
    <div class="flex items-center justify-between px-4 py-2 bg-night-blue border-b border-gray-200">
      <div>
        <h3 class="text-lg text-white">Flight {{ flight.order }}</h3>
        <p class="text-sm text-gray-300">{{ flight.tee.slice(0, -3) }}</p>
      </div>
      <button v-if="isAdmin" @click="isOverlayOpen = true" class="px-2 py-1 text-sm font-medium text-gray-700 rounded bg-gray-200 outline-none">Edit</button>
    </div>
    <div class="px-4 py-2 grid grid-cols-2">
      <div v-for="player in flight.players" :key="player.id" class="py-2">
        <p class="text-sm font-semibold text-gray-700">{{ player.pretty_name }}</p>
        <p class="text-xs text-gray-600">{{ player.handicap }}</p>
      </div>
    </div>
    <!-- Edit Overlay -->
    <TransitionRoot appear :show="isOverlayOpen" as="template">
      <Dialog as="div" @close="closeOverlay" class="relative z-10">
        <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
              class="flex min-h-full items-center justify-end h-screen text-center"
          >
            <TransitionChild
                as="template"
                enter="transform transition ease-in-out duration-300"
                enter-from="translate-x-full"
                enter-to="translate-x-0 z-50"
                leave="transform transition ease-in-out duration-300"
                leave-from="translate-x-0"
                leave-to="translate-x-full"
            >
              <DialogPanel
                  class="flex flex-col gap-4 w-full max-w-md h-screen transform overflow-y-auto rounded-l-2xl bg-white p-10 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                    as="h3"
                    class="flex flex-col gap-3 items-center gap-2 text-base font-medium leading-6"
                >
                  <div class="flex w-full items-center justify-between">
                    <h1>Modifier le flight</h1>
                  </div>
                </DialogTitle>

                <div class="mt-2 px-4 py-2 flex flex-col gap-2">
                  <label class="text-sm">Heure de départ:</label>
                  <input type="time" v-model="flight.tee" class="rounded-md p-2 outline-none custom-shadow border">
                </div>

                <div class="mt-2 px-4 py-2  max-h-3/4">
                  <div v-for="availability in event.availabilities" class="flex gap-4 py-2 items-center">
                    <div class="rounded-full aspect-square bg-night-blue p-1 text-tiny justify-center items-center flex h-10">
                      {{ availability.user.pretty_name[0]}}
                    </div>
                    <p class="flex-grow flex items-center">{{ availability.user.pretty_name }}</p>
                    <input type="checkbox"
                           :checked="flight.players.find(player => player.id === availability.user.id)"
                           @change="updateList(availability.user)">
                  </div>

                </div>

                <div class="flex justify-end gap-2 mt-4">
                  <button
                      type="button"
                      class="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-600 button-shadow"
                      @click="closeOverlay"
                  >
                    Annuler
                  </button>
                  <button
                      type="button"
                      class="inline-flex justify-center rounded-md border border-transparent bg-orange px-4 py-2 text-sm font-medium text-white button-shadow"
                      @click="submitForm"
                  >
                    Mettre à jour
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  Dialog, DialogPanel, DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
export default {
  name: 'FlightCard',
  props: {
    flight: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOverlayOpen: false
    }
  },
  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr)
      return date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) + ', ' + date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    },
    async openOverlay () {
      this.isOverlayOpen = true
    },
    closeOverlay () {
      this.isOverlayOpen = false
    },
    updateList (user) {
      const index = this.flight.players.findIndex(player => player.id === user.id)
      if (index === -1) {
        this.flight.players.push(user)
      } else {
        this.flight.players.splice(index, 1)
      }
    },
    async submitForm () {
      await this.$store.commit('setIsLoading', true)
      const id = this.event.id
      const formData = this.flight
      formData.players = this.flight.players.map(player => player.id)
      await this.$store.dispatch('updateFlight', {id, formData})
      await this.$store.commit('setIsLoading', false)
      this.closeOverlay()
    },
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  },
  computed: {
    event () {
      return this.$store.state.event
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  }
}
</script>
<style>
/* Tailwind CSS styles go here */
</style>
