import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './index.css'
import axios from "axios";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

createApp(App).use(store).use(router).component('Datepicker', Datepicker).mount('#app')
