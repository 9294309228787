import { createStore } from 'vuex'
import axios from "axios";
import router from "@/router";

export default createStore({
  state: {
      jwt: localStorage.getItem('t'),
      endpoints: {
          obtainJWT: process.env.VUE_APP_BACKEND_URL + '/auth/obtain_token',
          refreshJWT: process.env.VUE_APP_BACKEND_URL + '/auth/refresh_token'
      },
      isAuthenticated: false,
      token: '',
      isLoading: false,
      user: {
          is_superuser: false,
          is_staff: false,
          pretty_name: ""
      },
      player: {
          first_name: '',
          last_name: '',
          pretty_name: '',
          email: '',
          handicap: 36.0,
          sex: ''
      },
      users: [],
      event: {
          flights: [],
          availabilities: [],
      },
      events: [],
      errors: [],
      flight: {},
  },
  getters: {
      isSuperuser: (state) => {
          return state.user.is_superuser
      },
      isAdmin: (state) => {
          return state.user.is_staff
      },
  },
  mutations: {
      initializeStore(state) {
          if (localStorage.getItem('t')) {
              state.jwt = localStorage.getItem('t')
              state.isAuthenticated = true
          } else {
              state.token = ''
              state.isAuthenticated = false
          }
      },
      async login(state, path) {
          await axios.get("/auth/users/me/")
              .then(async response => {
                  state.user = response.data
                  state.isAuthenticated = true
                  await router.push(path)
              })
              .catch(error => {
                  if (error.response) {
                      for (const property in error.response.data) {
                          console.log(`${property}: ${error.response.data[property]}`)
                      }
                  } else {
                      console.log('Something went wrong. Please try again')
                      console.log(error)
                      console.log(JSON.stringify(error))
                  }
              })
      },
      logout(state) {
          state.user = {}
          state.team = {}
          state.isAuthenticated = false
          state.isAdmin = false
      },
      setIsLoading(state, status) {
          state.isLoading = status
      },
      setIsAuthenticated(state, value) {
          state.isAuthenticated = value
      },
      updateToken(state, newToken){
          localStorage.setItem('t', newToken);
          state.jwt = newToken;
      },
      removeToken(state){
          localStorage.removeItem('t');
          state.jwt = null;
      },
      addToErrors(state, error) {
          state.errors.push(error)
      },
      resetErrors(state) {
          state.errors = []
      },
      setEvents(state, events) {
          state.events = events
      },
      setEvent(state, event) {
          state.event = event
      },
      setUser(state, user) {
          state.user = user
      },
      setPlayer(state, user) {
          state.player = user
      },
      setUsers(state, users) {
          state.users = users
      },
      setFlight(state, flight) {
          state.flight = flight
      }
  },
  actions: {
      async signIn(context, formData) {
      context.commit('resetErrors')
      axios.defaults.headers.common.Authorization = ""
      await axios
          .post("/auth/token/login/", formData)
          .then(async response => {
            await context.commit('setIsAuthenticated', true)
            const token = response.data.auth_token
            await context.commit('updateToken', token)
            axios.defaults.headers.common.Authorization = "Token " + token
            await context.commit('login', {name: 'home'})
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                context.commit('addToErrors',`${error.response.data[property]}`)
              }
            } else {
              this.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
    },
      async signUp(context, formData) {
      context.commit('resetErrors')
      axios.defaults.headers.common.Authorization = ""
      await axios
          .post("/auth/users/", formData)
          .then(async response => {
            await context.dispatch('signIn', {
              email: formData.email,
              password: formData.password
            })
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                context.commit('addToErrors',`${error.response.data[property]}`)
              }
            } else {
              this.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
    },
      async createUser(context, formData) {
          context.commit('resetErrors')
          await axios
              .post("/auth/users/", formData)
              .then(async response => {
              })
              .catch(error => {
                  if (error.response) {
                      for (const property in error.response.data) {
                          context.commit('addToErrors',`${error.response.data[property]}`)
                      }
                  } else {
                      this.errors.push('Something went wrong. Please try again')

                      console.log(JSON.stringify(error))
                  }
              })
      },
      async updateMyProfile(context, formData) {
          await axios.put('/auth/users/me/', formData)
              .then(response => {
                  context.commit('setUser', response.data)
              })
              .catch(error => {
                  if (error.response) {
                      for (const property in error.response.data) {
                          context.commit('addToErrors',`${property}: ${error.response.data[property]}`)
                      }
                  } else {
                      this.errors.push('Something went wrong. Please try again')

                      console.log(JSON.stringify(error))
                  }
              })
      },
      async updateProfile(context, formData) {
          await axios.put(`/api/users/${formData.id}/`, formData)
              .then(response => {
                  context.commit('setPlayer', response.data)
              })
              .catch(error => {
                  if (error.response) {
                      for (const property in error.response.data) {
                          context.commit('addToErrors',`${property}: ${error.response.data[property]}`)
                      }
                  } else {
                      this.errors.push('Something went wrong. Please try again')

                      console.log(JSON.stringify(error))
                  }
              })
      },
      async resetPwd(context, formData) {
      context.commit('resetErrors')
      await axios
          .post("/auth/users/reset_password_confirm/", formData)
          .then(async response => {
            await router.push({name: 'login'})
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                context.commit('addToErrors',`${property}: ${error.response.data[property]}`)
              }
            } else {
              this.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
    },
      async createEvent(context, formData) {
          await axios.post('api/events/', formData)
              .then(response => {
                  context.dispatch('getEvents')
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async getEvents(context) {
          await axios.get('api/events/')
              .then(response => {
                  context.commit('setEvents', response.data)
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async getEvent(context, id) {
          await axios.get(`api/events/${id}/`)
              .then(response => {
                  context.commit('setEvent', response.data)
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async editEvent(context, {formData, id}) {
          await axios.put(`api/events/${id}/`, formData)
              .then(response => {
                  context.commit('setEvent', response.data)
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async deleteEvent(context, id) {
          await axios.delete(`api/events/${id}/`)
              .then(response => {
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async generateFlights(context, id) {
          await axios.post(`api/events/${id}/flights/`)
              .then(response => {
                  context.commit('setEvent', response.data)
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async updateFlight(context, {id, formData}) {
          await axios.put(`/api/flights/${formData.id}/`, formData)
              .then(response => {
                  context.dispatch('getEvent', id)
              })
              .catch(error => {
                  if (error.response) {
                      for (const property in error.response.data) {
                          context.commit('addToErrors',`${property}: ${error.response.data[property]}`)
                      }
                  } else {
                      this.errors.push('Something went wrong. Please try again')

                      console.log(JSON.stringify(error))
                  }
              })
      },
      async getUsers(context) {
          await axios.get('api/users/')
              .then(response => {
                  context.commit('setUsers', response.data)
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async getUser(context, id) {
          await axios.get(`api/users/${id}/`)
              .then(response => {
                  context.commit('setPlayer', response.data)
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async createPlayer(context, formData) {
          await axios.post('api/users/', formData)
              .then(response => {
                  context.dispatch('getUsers')
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async updateUser(context, formData) {
          await axios.put(`/api/users/${formData.id}/`, formData)
              .then(response => {
                  context.dispatch('getUsers')
              })
              .catch(error => {
                  if (error.response) {
                      for (const property in error.response.data) {
                          context.commit('addToErrors',`${property}: ${error.response.data[property]}`)
                      }
                  } else {
                      this.errors.push('Something went wrong. Please try again')

                      console.log(JSON.stringify(error))
                  }
              })
      },
      async createAvailability(context, formData) {
          await axios.post('api/availabilities/', formData)
              .then(response => {
                  context.dispatch('getEvents')
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async updateAvailability(context, formData) {
          await axios.put(`api/availabilities/${formData.id}/`, formData)
              .then(response => {
                  context.dispatch('getEvents')
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async getHomeData(context) {
          await axios.get(`api/home/`)
              .then(response => {
                  context.commit('setEvent', response.data.event)
                  context.commit('setFlight', response.data.flight)
              })
              .catch(error => {
                  console.log(error)
              })
      },
      obtainToken(username, password){
          const payload = {
              username: username,
              password: password
          }
          axios.post(this.state.endpoints.obtainJWT, payload)
              .then((response)=>{
                  this.commit('updateToken', response.data.token);
              })
              .catch((error)=>{
                  console.log(error);
              })
      },
      refreshToken(){
          const payload = {
              token: this.state.jwt
          }
          axios.post(this.state.endpoints.refreshJWT, payload)
              .then((response)=>{
                  this.commit('updateToken', response.data.token)
              })
              .catch((error)=>{
                  console.log(error)
              })
      },
      inspectToken(){
          const token = this.state.jwt;
          if(token){
              const decoded = jwt_decode(token);
              const exp = decoded.exp
              const orig_iat = decode.orig_iat
              if(exp - (Date.now()/1000) < 1800 && (Date.now()/1000) - orig_iat < 628200){
                  this.dispatch('refreshToken')
              } else if (exp -(Date.now()/1000) < 1800){
                  // DO NOTHING, DO NOT REFRESH
              } else {
                  // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
              }
          }
      },
  },
  modules: {
  }
})
