import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/Signup.vue')
  },
  {
    path: '/reset-password/',
    name: 'reset-pwd',
    component: () => import('../views/ForgotPwd.vue'),
  },
  {
    path: '/password-reset/:uid/:token',
    name: 'pwd-reset',
    component: () => import('../views/ResetPwd.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue'),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/EventsView.vue'),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/create-user',
    name: 'createUser',
    component: () => import('../views/CreateUserView.vue'),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/create-player',
    name: 'createPlayer',
    component: () => import('../views/CreatePlayerView.vue'),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/UsersView.vue'),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/users/:id',
    name: 'user',
    component: () => import('../views/UserProfileView.vue'),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/MyProfile.vue'),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/addevent',
    name: 'addEvent',
    component: () => import('../views/AddEventView.vue'),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/event/:id',
    name: 'event',
    component: () => import('../views/EventView.vue'),
    meta: {
      requireLogin: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'login', query: { to: to.path } });
  } else if (to.matched.some(record => record.meta.requireAdmin) && !store.state.user.is_staff) {
    next({ name: 'home'})
  } else {
    next()
  }
})

export default router
