<template>
  <div class="h-full overflow-y-auto ">
    <HeaderComponent class="h-16"></HeaderComponent>

  <div class="flex flex-col gap-2 p-8">
    <h1 class="text-2xl font-extralight text-gray-800">Bonjour {{user.first_name}},</h1>
    <h2 class="mt-6">Votre prochain événement:</h2>
    <div
        v-if="event.id"
         class="bg-light-blue px-6 py-4 rounded-2xl flex justify-between button-shadow">
      <router-link :to="{name: 'event', params: {id: event.id}}" class="rounded-2xl flex gap-4">
        <div class="flex flex-col bg-night-blue text-white rounded-2xl aspect-square h-16 items-center justify-around">
          <div>
            <p class="text-tiny text-center">{{ getMonth(event.date) }}</p>
            <p class="text-2xl text-center">{{ getDay(event.date) }}</p>
          </div>
        </div>
        <div class="flex flex-col">
          <h1 class="w-full font-extralight text-lg">{{event.name}}</h1>
          <p class="text-tiny text-gray-400">{{event.venue_name}}</p>
        </div>
      </router-link>
      <div class="flex items-center justify-between gap-1">
        <CheckCircleIcon
            @click="markPresent(event, 'present')"
            :class="available(event) ? 'text-green-400' : 'text-gray-400'"
            class="h-8 w-8 rounded-full"></CheckCircleIcon>
        <XCircleIcon
            @click="markPresent(event, 'absent')"
            :class="!available(event) ? 'text-red-500' : 'text-gray-400'"
            class="h-8 w-8"></XCircleIcon>
      </div>
    </div>
    <h2 class="mt-6">Votre prochain flight:</h2>
    <FlightCard v-if="flight" :flight="flight"></FlightCard>
  </div>
  </div>
  
</template>

<script>
import {
  CheckCircleIcon, XCircleIcon,
} from '@heroicons/vue/24/outline';
import FlightCard from "@/components/FlightCard";
import HeaderComponent from "@/components/HeaderComponent.vue";


export default {
  name: 'HomePage',
  components: {
    FlightCard,
    CheckCircleIcon,
    XCircleIcon,
    HeaderComponent
  },
  data() {
    return {
      greeting: '',
    };
  },
  async mounted() {
    // Fetch the next event and next flight for the current user
    await this.$store.commit('setIsLoading', true)
    await this.$store.dispatch('getHomeData')
    await this.$store.commit('setIsLoading', false)
  },
  methods: {
    getMonth (date) {
      const options = {month: 'short'}
      return new Intl.DateTimeFormat("fr-FR", options).format(new Date(date))
    },
    getDay (date) {
      return (new Date(date)).getDate()
    },
    available (event) {
      let obj = event.availabilities.find(o => o.user.id === this.$store.state.user.id)
      if (obj) {
        return obj.status === 'present'
      }
      return false
    },
    markPresent (event, status) {
      let obj = event.availabilities.find(o => o.user.id === this.$store.state.user.id)
      let formData
      if (obj) {
        // update
        obj.status = status
        obj.user= obj.user.id
        this.$store.dispatch('updateAvailability', obj)
      } else {
        // create
        formData = {
          user: this.$store.state.user.id,
          event: event.id,
          status: status
        }
        this.$store.dispatch('createAvailability', formData)
      }
    },
  },
  computed: {
    event () {
      return this.$store.state.event
    },
    flight () {
      return this.$store.state.flight
    },
    user () {
      return this.$store.state.user
    }
  }
};
</script>

<style>
/* Styles go here */
</style>
