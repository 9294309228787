<template>
    <div class="relative h-16 p-4 w-full bg-night-blue px-4 flex justify-start items-center text-white">
        <button v-if="back" @click="goBack"><ArrowLeftIcon class="aspect-auto h-6"></ArrowLeftIcon></button>
        <h1 class="absolute flex items-center text-2xl left-1/2 transition -translate-x-1/2 capitalize">{{ header }}</h1>
    </div>
</template>

<script>
import {
  ArrowLeftIcon
} from '@heroicons/vue/24/outline';
export default {
  name: 'HeaderComponent',
  props: {
    header: {
      type: String,
    },
    back: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ArrowLeftIcon
  },
  methods: {
    goBack () {
        this.$router.back()
    }
  }
}
</script>